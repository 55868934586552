import { FC, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { IfFeatureEnabled } from '@growthbook/growthbook-react'

import SuppliersCarousel from './SuppliersCarousel/SuppliersCarousel'
import TrustedByLogos from './TrustedByLogos'
import SupplierCirclesCarousel from './SupplierCirclesCarousel'
import FeaturesBenefitsSection from './FeaturesBenefitsSection'
import CallToActionTwoColumns from './CallToActionTwoColumns/CallToActionTwoColumns'
import useRecommendedForYou from '../../services/recommendationsAPI/useRecommendedForYou'
import ProductCarousel from '../Carousels/ProductCarousel'
import { AnalyticsProductCarouselTypes } from '../../utils/types/analytics'
import HeroSectionV2 from './HeroSectionV2'
import { useApi } from '../../services/useApi'
import useCategoryDataStructure from '../hooks/useCategoryDataStructure'
import { removeHomeCategory } from '../../lib/category'
import { createUrlParams } from '../../lib/params'
import { Product } from '../../utils/types/Product'
import { ProductBaseIncludes, ProductListFieldsQuery, ResourceTag } from '../../utils/constants'

import styles from '../../styles/LandingPage/LandingPage.module.less'

const LandingPage: FC = () => {
  const { t } = useTranslation('landing')
  const { getResource } = useApi()
  const { categories } = useCategoryDataStructure()

  const {
    data: recentlyViewedProducts,
    triggerHomePageView,
  } = useRecommendedForYou({ pageSize: 60 })

  const mainCategoryIds = removeHomeCategory(categories).map((cat) => cat.id) || []
  const mainCategoriesQuery = createUrlParams({
    filter: {
      f_catid: mainCategoryIds,
    },
  })
  const query = `include=${ProductBaseIncludes.join(',')}${mainCategoriesQuery}&page[limit]=20${ProductListFieldsQuery}`
  const { data: products } = getResource<Product>(ResourceTag.product, query)

  // Load recommended products on page load
  useEffect(() => {
    triggerHomePageView()
  }, [])

  return (
    <>
      <HeroSectionV2 />
      <div className={`${styles['landing-page-main']} ${styles['with-hero-section-container']}`}>

        <IfFeatureEnabled feature="homepage-show-trust-logos-top">
          <TrustedByLogos className="padding-top-spacer-tripple" />
        </IfFeatureEnabled>

        <SupplierCirclesCarousel hideTitle />

        <ProductCarousel
          carouselType={AnalyticsProductCarouselTypes.RecommendedForYou}
          productCardType="narrow"
          title={t('landing:Bestsellers, prepare for the season')}
          products={products}
          className="margin-top-spacer-quadruple margin-bottom-spacer-quadruple"
        />

        <ProductCarousel
          carouselType={AnalyticsProductCarouselTypes.RecommendedForYou}
          productCardType="narrow"
          title={t('products:Recommended for you')}
          products={recentlyViewedProducts?.slice(0, 15)}
          className="margin-top-spacer-quadruple margin-bottom-spacer-quadruple"
        />

        <FeaturesBenefitsSection />

        <SuppliersCarousel />

        <ProductCarousel
          carouselType={AnalyticsProductCarouselTypes.RecommendedForYou}
          productCardType="narrow"
          title={t('products:Recommended for you')}
          products={recentlyViewedProducts?.slice(15, -1)}
          hasBrowseLink
        />

        <TrustedByLogos
          isCentered
          className="margin-top-spacer-quadruple padding-top-spacer-tripple" // Stack margin+padding spacers to match design
        />

        <IfFeatureEnabled feature="homepage-show-cta-cards-section">
          <CallToActionTwoColumns title={t('landing:Ready to redefine the way you buy')} />
        </IfFeatureEnabled>
      </div>
    </>
  )
}

export default LandingPage
