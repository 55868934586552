import { FC } from 'react'
import { SWRConfig } from 'swr'

import { WithFallback } from '../../utils/types/misc'

const PageWithFallback = <Props extends {}>(Component: FC<WithFallback<Props>>) => (
  (props: WithFallback<Props>): JSX.Element => {
    const {
      // When doing a shallow navigate from a 404 page to another valid link of the same page,
      // fallback will be undefined and cause a crash in SWR. This fallback should prevent
      // it from crashing
      fallback = {},
      ...rest
    } = props
    // Need to cast type to avoid type error from Omit<T>
    // https://github.com/microsoft/TypeScript/issues/35858
    const pageProps = rest as Props & { fallback: never }
    return (
      <SWRConfig value={{ fallback }}>
        <Component
          {...pageProps}
        />
      </SWRConfig>
    )
  })

export default PageWithFallback
