import { Button } from 'rsuite'
import { FC, MouseEventHandler, ReactNode } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import Img from '../Img'
import { imageLoader } from '../../utils/images'
import { getCountryAndLocaleStrings } from '../../utils/locales'
import SafeTranslate from '../common/SafeTranslate'
import useUrls from '../../services/useUrls'
import useLoginModal from '../../services/useLoginModal'
import { useAuth } from '../../services/useAuth'

import buttonStyles from '../../styles/CustomButtons.module.less'
import styles from '../../styles/LandingPage/FeaturesBenefitsSection.module.less'

interface FeaturesBenefitsSectionProps {
  tagline: ReactNode
  headline: ReactNode
  description: ReactNode
  valueProps: string[]
  cta: ReactNode
  href: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

const FeaturesBenefitsSection: FC = () => {
  const { locale } = useRouter()
  const { t } = useTranslation('landing')
  const { locale: lang } = getCountryAndLocaleStrings(locale)
  const { pushT } = useUrls()
  const { openRegisterModal } = useLoginModal()
  const { user } = useAuth()

  const showFirstRowFeatureFlag = useFeatureIsOn('homepage-show-valueprop-section-1')
  const showSecondRowFeatureFlag = useFeatureIsOn('homepage-show-valueprop-section-2')

  const ValuePropsContent: FC<FeaturesBenefitsSectionProps> = ({
    tagline,
    headline,
    description,
    valueProps,
    cta,
    href,
    onClick = () => {},
  }) => (
    <div className={styles['features-benefits-content']}>
      <div className={styles.tagline}>{tagline}</div>
      <h2 className={styles.headline}>{headline}</h2>
      <div className={styles.description}>{description}</div>
      <ul className={styles.values}>
        {valueProps.map((valueProp) => (
          <li key={valueProp}>{t(valueProp)}</li>
        ))}
      </ul>
      <Link
        className={styles.cta}
        href={href}
        onClick={onClick}
      >
        <Button
          appearance="ghost"
          className={`
            ${styles['cta-button']}
            ${buttonStyles['button-primary']}
            ${buttonStyles['button-transparent']}
            ${buttonStyles['custom-button']}
            ${buttonStyles['button-caret']}
          `}
        >
          {cta}
        </Button>
      </Link>
    </div>
  )

  if (!showFirstRowFeatureFlag && !showSecondRowFeatureFlag) {
    return null
  }

  return (
    <section className={styles['features-benefits-section']}>
      {showFirstRowFeatureFlag && (
        <div className={styles.row}>
          <ValuePropsContent
            tagline={t('Sourcing')}
            headline={t('Find the right supplies for your needs, fast')}
            description={t('Leverage the purchasing power of Droppe’s 1000s of customers to find products for your order quantities, target prices and terms, hassle-free')}
            valueProps={['Find all possible options within Europe in one place', 'Tap into the purchasing power of 1500 customers', 'Set Price Alerts for your products and volumes']}
            cta={t('Start sourcing_2')}
            href="/products"
          />
          <div className={styles['image-container']}>
            <Img
              priority
              loader={imageLoader}
              src={imageLoader(`d24-sourcing-benefits-${lang}.webp`)}
              alt="Droppe Autonomous sourcing platform Visual Details"
              wMaxWidth="586px" // To match Figma
              layout="fill"
            />
          </div>
        </div>
      )}

      {showSecondRowFeatureFlag && (
        <div className={styles.row}>
          <div className={styles['image-container']}>
            <Img
              priority
              loader={imageLoader}
              src={imageLoader(`d24-ordering-benefits-${lang}.webp`)}
              alt="Droppe Autonomous sourcing platform Visual Details"
              wMaxWidth="586px" // To match Figma
              layout="fill"
            />
          </div>
          <ValuePropsContent
            tagline={t('Ordering')}
            headline={t('Drastically reduce time spent ordering')}
            description={(
              <p>
                <SafeTranslate
                  i18nKey="landing:Instead of ordering from each supplier via calls and emails, create Smart Catalogs for your team—enabling everyone to purchase pre-approved products specific to their roles in the shortest time possible"
                  components={{
                    span: <span className="highlight-text" />,
                    br: <br />,
                    strong: <strong />,
                  }}
                />
              </p>
          )}
            valueProps={['Single process to order from your suppliers', 'Centralize orders to reduce admin work', 'Easily coordinate orders with your team']}
            cta={t('Create your smart catalog')}
            href="#register"
            onClick={(e) => {
              e.preventDefault()
              if (user) {
                pushT('/my-catalogs')
              } else {
                openRegisterModal()
              }
            }}
          />
        </div>
      )}
    </section>
  )
}

export default FeaturesBenefitsSection
